import {WineCustomAttributeType} from '../Interface/WineCustomAttributeType';
import {MagentoItem} from './MagentoItem';
import {WD} from '..';
import {parseDate, getSGTime, getRegexMatches, decodeChar, isSameDay} from '../Helper/Helper';
import {IExpressEligible} from '../Interface/IWDExpress';

export class Wine extends MagentoItem {
  constructor(item: MagentoItem) {
    super(item.getData());
  }
  getDescription() {
    return this.getCustomAttributeValue(WineCustomAttributeType.description);
  }
  getProducer() {
    return this.getCustomAttributeValue(WineCustomAttributeType.producer);
  }
  getML() {
    return this.getCustomAttributeValue(WineCustomAttributeType.bottle_size);
  }
  getAlcoholPercent() {
    return this.getCustomAttributeValue(WineCustomAttributeType.alcohol);
  }
  getAwards() {
    return this.getCustomAttributeValue(WineCustomAttributeType.awards_and_recognition);
  }
  getTestingNotes() {
    return this.getCustomAttributeValue(WineCustomAttributeType.short_description);
  }
  getCountryId() {
    return this.getCustomAttributeValue(WineCustomAttributeType.country);
  }
  getRegionId() {
    return this.getCustomAttributeValue(WineCustomAttributeType.region);
  }
  getYear() {
    return this.getCustomAttributeValue(WineCustomAttributeType.vintage_year);
  }
  hasYear() {
    return Number.parseInt(this.getYear(), 10) > 0;
  }
  getTypeId() {
    return this.getCustomAttributeValue(WineCustomAttributeType.type);
  }
  getGrapeId() {
    return this.getCustomAttributeValue(WineCustomAttributeType.grape_varieties);
  }
  getDiscountDisplayString() {
    const promotionText =
      this.getTieredPrices().length > 0 ? WD.store.appLocale.customer_web.wine_card.qty_discount : '';
    // promotionText = this.getSpecialPrice() ? 'Price Discount' : promotionText;
    return promotionText;
  }
  getNameAndYear(): string {
    let str = this.getName();
    if (this.hasYear()) {
      const year = this.getYear();
      str = str.replace(year, '');
      str += ` ${year}`;
    } else {
      str += ` ${WD.store.appLocale.customer_web.wine_card.nv}`;
    }
    return str;
  }
  isAvailable() {
    return this.data.status === 1 && this.getStock() > 0;
  }
  isBaseProduct() {
    return this.data.sku.indexOf('M') < 0;
  }
  isMerchantProduct() {
    return this.data.sku.indexOf('M') >= 0;
  }
  isInStock() {
    if (!this.data.extension_attributes || !this.data.extension_attributes.stock) {
      return true;
    }
    return this.data.extension_attributes.stock.is_in_stock;
  }
  getStock() {
    if (!this.data.extension_attributes || !this.data.extension_attributes.stock) {
      return 9999;
    }
    return this.data.extension_attributes.stock.qty;
  }
  isOrganic() {
    return this.getCategories().indexOf('Organic') >= 0;
  }
  isSparklingWine() {
    return this.getCategories().indexOf('Sparkling Wine') >= 0;
  }
  isSweetFortifiedWine() {
    return this.getCategories().indexOf('Sweet and Fortified Wine') >= 0;
  }
  isNonAlcoholic() {
    return this.getCategories().indexOf('Non-Alcoholic') >= 0;
  }
  getEarliestDeliveryDate() {
    const dateString = this.getCustomAttributeValue(WineCustomAttributeType.wd_earliest_delivery_date);
    if (dateString) {
      return parseDate(dateString);
    }
    return null;
  }
  getEarliestDeliveryTimeSlot() {
    return this.getCustomAttributeValue(WineCustomAttributeType.wd_earliest_delivery_time_slot);
  }
  getEarliestCollectionDate() {
    const dateString = this.getCustomAttributeValue(WineCustomAttributeType.wd_earliest_collection_date);
    if (dateString) {
      return parseDate(dateString);
    }
    return null;
  }
  isNextDayDelivery() {
    const tomorrow = new Date(getSGTime().getTime() + 24 * 60 * 60 * 1000);
    const earliest = this.getEarliestDeliveryDate();
    if (earliest) {
      return isSameDay(tomorrow, earliest);
    }
    return false;
  }
  expressDeliveryEligible(): IExpressEligible {
    const today = new Date(getSGTime().getTime());
    const tomorrow = new Date(getSGTime().getTime() + 24 * 60 * 60 * 1000);
    const cutOffTime = WD.store.expressCutOffTime
      ? new Date(WD.store.expressCutOffTime.replace(/-/g, '/'))
      : new Date();
    const earliest = this.getEarliestDeliveryDate();
    if (earliest) {
      if (isSameDay(today, earliest)) {
        if (today.getHours() >= 0 && today.getHours() <= cutOffTime.getHours()) {
          return {
            valid: true,
            available: 'Available Today',
            receive: WD.store.appLocale.customer_web.wine_card.receive_today,
          };
        }
      }
      if (isSameDay(tomorrow, earliest)) {
        if (today.getHours() >= cutOffTime.getHours() && today.getHours() <= 23) {
          return {
            valid: true,
            available: 'Available Tomorrow',
            receive: WD.store.appLocale.customer_web.wine_card.receive_tomorrow,
          };
        }
      }
    }
    return {
      valid: false,
      available: '',
      receive: '',
    };
  }
  getWineType() {
    return WD.getType(this.getTypeId());
  }
  getGrapeVariety() {
    return decodeChar(WD.getGrapeVariety(this.getGrapeId()));
  }
  getCountry() {
    return WD.getCountry(this.getCountryId());
  }
  getRegion() {
    return WD.getRegion(this.getRegionId());
  }
  getMerchantId() {
    const matches = getRegexMatches(/WD\-[0-9]*?\-(M[0-9]*)/gm, this.getSku());
    if (matches.length > 0) {
      return matches[0];
    }
    return 'UNKNOWN';
  }
}
