import { format } from 'date-fns';
import {
  getPlatform,
  isEqual,
  IElasticSearchOptions,
  removePunctuation,
  removeStopWords,
  getWebBaseUrl,
  Logger,
  removeIllegalUrlChars,
  hash,
} from '@wine-delivery/wd-shared-lib';
import * as React from 'react';
import { setUrl } from './NavigationHelper';
// import { NavigationHelper } from '../Handler/NavigationHelper';

export const convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

type DateFormat = 'yyyy-MM-dd' | 'dd MMM yyyy' | 'dd MMM yyyy hh:mm a';
export const formatDate = (date: Date, newFormat: DateFormat, castTimeZone = false): string => {
  return format(
    castTimeZone ? new Date(date.setUTCMilliseconds(new Date().getTimezoneOffset() * -1 * 60 * 1000)) : date,
    newFormat,
  );
};

export const shallowCompare = function (dis: any, nextProp: any, nextState: any) {
  return !isEqual(dis.props, nextProp) || !isEqual(dis.state, nextState);
};

export const fileToURL = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (e: any) => {
      const result = e.target.result;
      resolve(result);
    };
    reader.readAsDataURL(file);
  });
};
export const dataURItoBlob = (dataURI: string) => {
  const arr = dataURI.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  // @ts-ignore
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const applyClass = <T>(obj: any, clazz: new () => T): T => {
  const newObject = new clazz();
  Object.assign(newObject, obj);
  return newObject;
};
export const applyClasses = <T>(objs: any[], clazz: new () => T): T[] => {
  return objs.map((obj) => applyClass(obj, clazz));
};

export const getMissingAttributes = <T>(obj: T, attributes: (keyof T)[]): string[] => {
  // @ts-ignore
  const missing = [];
  attributes.forEach((att) => {
    if (obj[att] === undefined || (obj[att] as any) === '') {
      missing.push(att);
    }
  });
  // @ts-ignore
  return missing;
};

export const toIndexedList = <T>(objects: { index: number }[], c?: new () => T): T[] => {
  if (!objects) {
    return [];
  }
  const items: T[] = [];
  objects.forEach((o) => {
    const item = c ? new c() : o;
    if (c) {
      Object.assign(item, o);
    }
    items[o.index] = item as any;
  });
  return items;
};

export const getValues = <T>(object: { [key: string]: T }): T[] => {
  if (object) {
    return Object.keys(object).map((key) => object[key]);
  } else {
    return [];
  }
};
// @ts-ignore
let responsiveNameOverride = undefined;
export type ResponsiveName = 'web' | 'tablet' | 'phone' | 'screen';
export const setResponsiveNameOverride = (name: ResponsiveName) => {
  responsiveNameOverride = name;
};
export const getResponsiveName = (width?: number): ResponsiveName => {
  // @ts-ignore
  if (responsiveNameOverride) {
    // @ts-ignore
    return responsiveNameOverride;
  }
  if (!width && !window) {
    return 'web';
  }
  if (!width) {
    width = window.innerWidth;
  }
  if (width <= 764) {
    return 'phone';
  }
  // if (width <= 1023) {
  //     return "tablet";
  // }
  if (width <= 1440) {
    return 'web';
  }
  if (width <= 1920) {
    return 'screen';
  }
  return 'web';
};

export const selectResponsive = <T>(op: { web?: T; tablet?: T; phone?: T; screen?: T }): T => {
  if (!window) {
    return op.web;
  }
  const width = window.innerWidth;

  if (width <= 764 && op.phone) {
    return op.phone;
  }
  // if (width <= 1023 && op.tablet) {
  //     return op.tablet;
  // }
  if (width <= 1440 && op.web) {
    return op.web;
  }
  if (width <= 1920 && op.screen) {
    return op.screen;
  }
  return op.web;
};

export const timing = <T extends React.Component>(dis: T) => {
  if (isDev()) {
    const start = new Date();
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        console.log('[TIMER]', dis['constructor']['name'], new Date().getTime() - start.getTime() + 'ms');
      });
    });
  }
};

export const onInputChange = <T extends React.Component<any, { values: any; errors: { [key: string]: string } }>>(
  dis: T,
  fieldName: string,
  // @ts-ignore
  { additionalOnChange }: { additionalOnChange?: (value, error) => any } = {},
) => {
  // @ts-ignore
  return (value, error: string) => {
    dis.setState(
      {
        values: { ...dis.state.values, [fieldName]: value },
        errors: { ...dis.state.errors, [fieldName]: error },
      },
      () => {
        if (additionalOnChange) additionalOnChange(value, error);
      },
    );
  };
};

export const isBrowser = () => {
  return getPlatform() === 'web';
};

export const changeHTMLElementInnerHTMLByID = (id: string, content: any) => {
  try {
    const des = document.getElementById(id);
    if (des) {
      const current = des.innerHTML;
      if (current !== content) {
        des.innerHTML = content;
      }
    }
  } catch (e) {}
};
export const changeHTMLElementContentByName = (name: string, content: string) => {
  try {
    const des = document.getElementsByName(name)[0];
    if (des) {
      const current = des.getAttribute('content');
      if (current !== content) {
        des.setAttribute('content', content);
      }
    }
  } catch (e) {}
};
export const changeHTMLElementContentById = (id: string, content: string) => {
  try {
    const des = document.getElementById(id);
    if (des) {
      const current = des.getAttribute('content');
      if (current !== content) {
        des.setAttribute('content', content);
      }
    }
  } catch (e) {}
};
export const sanitizeString = (str: string) => {
  if (!str) {
    return '';
  }
  return str.replace(/(&nbsp;|<([^>]+)>)/gm, '').replace(/[\"\']/gm, ``);
};
const defaultTitle = 'Wine.Delivery: Enjoy free delivery island-wide for a wide range of wines in Singapore.';
let currentTitle = defaultTitle;
export const setPageTitle = (title: string) => {
  title = sanitizeString(title);
  changeHTMLElementInnerHTMLByID('title', title);
  changeHTMLElementContentById('ogTitle', title);
  currentTitle = title;
};

const defaultDescription =
  'Wine.Delivery is the premium marketplace to buy wine online in Singapore. Enjoy free delivery island-wide for a wide range of wines.';
let currentDescription = defaultDescription;
export const setPageDescription = (description: string) => {
  description = sanitizeString(description);
  if (!description) {
    description = defaultDescription;
  }
  changeHTMLElementContentByName('description', description);
  changeHTMLElementContentById('ogDescription', description);
  currentDescription = description;
};

const defaultOgImage = 'https://wine.delivery/static/image/logo.png';
let currentOgImage = defaultOgImage;
export const setOgImage = (imageLink: string = defaultOgImage) => {
  imageLink = sanitizeString(imageLink);
  changeHTMLElementContentById('ogImage', imageLink);
  currentOgImage = imageLink;
};

const defaultOgUrl = getWebBaseUrl();
let currentOgUrl = defaultOgUrl;
export const setOgUrl = (urlLink: string = defaultOgUrl): void => {
  urlLink = sanitizeString(urlLink);
  changeHTMLElementContentById('ogUrl', urlLink);
  currentOgUrl = urlLink;
};

const defaultSEOKeywords =
  'wine,wine delivery,wine delivery singapore,buy wine online,buy wine online singapore,wines,wine marketplace,wine singapore';
let currentSEOKeywords = defaultSEOKeywords;
export const setPageKeywords = (keywords: string, { postProcessing = true } = {}) => {
  if (postProcessing) {
    keywords = removeStopWords(keywords);
    keywords = sanitizeString(removePunctuation(keywords));
    const list = keywords.split(/[\s\t]/gm);
    keywords = list.filter((i) => i).reduce((p, c) => `${p ? p + ', ' : ''} ${c}`, '');
  }
  changeHTMLElementContentByName('keywords', keywords);
  currentSEOKeywords = keywords;
};

let currentStructuredData: any = {};
export const setStructuredData = (obj: any) => {
  changeHTMLElementInnerHTMLByID('structureData', JSON.stringify(obj, null, '\t'));
  currentStructuredData = obj;
};

let currentBreadcrumb: any = {};
export const setPageBreadcrumb = (list: { name: string; url: string }[]) => {
  let obj = {};
  if (list && list.length > 0) {
    obj = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: list.map(({ name, url }, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: name,
        item: (getWebBaseUrl() + url).replace(/\/\//gm, '/'),
      })),
    };
  }
  changeHTMLElementInnerHTMLByID('breadcrumb', JSON.stringify(obj, null, '\t'));
  currentBreadcrumb = obj;
};

export const getDocumentMetaData = () => ({
  title: currentTitle,
  description: currentDescription,
  keywords: currentSEOKeywords,
  structuredData: JSON.stringify(currentStructuredData),
  ogImage: currentOgImage,
  ogUrl: currentOgUrl,
  breadcrumb: JSON.stringify(currentBreadcrumb),
});

export const resetDocumentMetaData = () => {
  setPageTitle(defaultTitle);
  setPageDescription(defaultDescription);
  setPageKeywords(defaultSEOKeywords);
  setStructuredData({});
  setOgImage(defaultOgImage);
  setOgUrl(defaultOgUrl);
  setPageBreadcrumb([]);
};

export const focusSearchBar = () => {
  if (getResponsiveName() === 'phone') {
    const node2: HTMLInputElement = document.getElementById('mobileSearch') as any;
    if (node2) {
      node2.click();
    }
  } else {
    const node: HTMLInputElement = document.getElementById('search') as any;
    if (node) {
      node.focus();
    }
  }
};

export const isIE = () => {
  if (!window.document) {
    return false;
  }
  // @ts-ignore
  return false || !!document['documentMode'];
};

export const isSafari = () => {
  if (!isBrowser()) {
    return false;
  }
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const isMobileSafari = () => {
  //@ts-ignore
  if (!window.safari && isSafari()) {
    return true;
  }
  return false;
};
export const getBuildVersion = () => {
  const version = process.env.VERSION;
  if (version) {
    if (typeof version === 'string') {
      return JSON.parse(version);
    } else {
      return version;
    }
  } else {
    return 'NO VERSION FOUND';
  }
};

export const isDev = () => {
  return process.env.NODE_ENV !== 'production';
};

export const getNodeBackEndUrl = () => {
  if (isDev()) {
    return 'https://dev.winedelivery.tech/';
  } else {
    return '';
  }
};

export const getDomain = () => {
  return window.location.hostname;
};

export const isOnProductionHost = () => {
  const host = getDomain();
  if (host) {
    return host.indexOf('wine.delivery') >= 0;
  }
  return false;
};

let currentStatus = 200;
export const setStatus = (status: 200 | 404) => {
  currentStatus = status;
};

export const getStatus = () => {
  return currentStatus;
};

export const getUserAgent = () => {
  if (navigator && navigator.userAgent) {
    return navigator.userAgent;
  }
  return '';
};

export const isBot = () => {
  return /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(getUserAgent());
};

export const getRecommendationLink = (id: any, name: string) => {
  return `/recommendations/${id}/${encodeURIComponent(removeIllegalUrlChars(name))}`;
};

export const replaceUrlIfNotSame = (history: any, url: string) => {
  if (isBrowser()) {
    const currentUrl = window.location.pathname;
    if (currentUrl !== url) {
      setUrl(history, url);
    }
  }
};
export const SEARCH_ROUTE = '/Buy-Wine-Online';

export const getSearchBranchName = (options: IElasticSearchOptions) => {
  let branch: string;
  if (options.country) {
    branch = options.country;
  } else if (options.grape_varieties) {
    branch = options.grape_varieties;
  } else if (options.type) {
    branch = options.type;
  }
  return branch;
};

export const generateSearchPageDescription = (title: string) => {
  if (title.endsWith('Wine')) title = title.replace('Wine', '');
  return `Buy ${title.trim()} Wines at Wine Delivery, Shop huge selection of wines with free next day delivery and no minimum purchase.`;
};

export const generateSearchPageKeywords = (title: string) => {
  return `${title}, ${getGenericKeywords()}`;
};
export const getGenericKeywords = () => {
  return `Wine Delivery Singapore, singapore wine, wine online singapore, buy wine online singapore, wine delivery, gift wine ,singapore, wine online, wines`;
};
export const unregisterServiceWorker = async () => {
  const registrations = await navigator.serviceWorker.getRegistrations();
  Logger.logInfo('SERVICE WORKER', 'LIST', { registrations });
  for (const registration of registrations) {
    if (registration.scope.indexOf('winedelivery') >= 0 || registration.scope.indexOf('wine.delivery') >= 0) {
      Logger.logInfo('SERVICE WORKER', 'UNREGISTER', `Removing service worker...`, registration);
      await registration.unregister();
      Logger.logInfo('SERVICE WORKER', 'UNREGISTER', `Service worker Removed.`);
    }
  }
};
export const updateServiceWorkers = async () => {
  // await unregisterServiceWorker();
  // const keys = await window.caches.keys()
  // keys.forEach(async (key) => {
  //     Logger.logInfo("SERVICE WORKER", "REMOVE CACHE", key);
  //     await window.caches.delete(key);
  // })
};
export const unregisterServiceWorkerAndRestart = async () => {
  await unregisterServiceWorker();
  // location && location.reload();
};

export const getDeviceType = () => {
  if (navigator && navigator.userAgent) {
    if (navigator.userAgent.indexOf('Android') >= 0) {
      return 'android';
    } else if (navigator.userAgent.indexOf('iPhone') >= 0) {
      return 'iphone';
    } else if (navigator.userAgent.indexOf('node') >= 0) {
      return 'node.js';
    } else {
      return 'browser';
    }
  }
  return 'node.js';
};

export const openPage = (url: string) => {
  if (window) {
    window.open(url, '_blank');
  }
};

export const copyToClipboard = (str: string) => {
  const tempInput: HTMLInputElement = document.createElement('INPUT') as HTMLInputElement;
  document.body.appendChild(tempInput);
  tempInput.setAttribute('value', str);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
};

export const loadScript = (url: string) => {
  return new Promise<void>((resolve, reject) => {
    const urlHash = hash(url);
    if (!document.getElementById(urlHash)) {
      const ele = document.createElement('script');
      ele.setAttribute('src', url);
      ele.addEventListener('load', () => {
        resolve();
      });
      ele.addEventListener('error', () => {
        reject();
      });
      ele.id = urlHash;
      document.body.appendChild(ele);
    } else {
      resolve();
    }
  });
};

export const isIphoneX = () => {
  if (isBrowser()) {
    // @ts-ignore
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream'];
    const ratio = window.devicePixelRatio || 1;
    const screen = {
      width: window.screen.width * ratio,
      height: window.screen.height * ratio,
    };
    //longSide == 1792 || longSide == 2436 || longSide == 2688
    if (
      iOS &&
      ((screen.width == 1125 && screen.height === 2436) ||
        (screen.width == 1242 && screen.height === 2688) ||
        (screen.width == 828 && screen.height === 1792))
    ) {
      return true;
    }
  }
  return false;
};

export const getMobileOperatingSystem = () => {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
    return 'iOS';
  }
  return null;
};

export const chunkSubstr = (str: string, size: number) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

export const getVersionInfo = () => {
  const pack = require('../../package.json');
  const urgency = process.env.APP_UPDATE_URGENCY || 'NORMAL';
  const version = pack.version;
  const buildType = process.env.REACT_APP_BUILD_TYPE;
  return { urgency, version, buildType };
};
